import React from 'react'
import { ENGLISH_TITLE } from '../../components/data/English'
import { SEO } from '../../components/data/SEO'
import { CardService } from '../../components/molecules/Card/CardService'
import { LayoutService } from '../../components/organisms/Layout/LayoutService'

const cardList = [
  {
    serviceName: 'AI-OCR+RPA',
    imagePathPc: '/service/img/systemsolution/systemsolution1.png',
    imagePathSp: '/service/img/systemsolution/systemsolution1sp.png',
    title: 'バックオフィス業務のDX化',
    content:
      '「デジタイゼーション（アナログ・物理データのデジタルデータ化）」の領域における支援をおこないます。DX化におけるまず最初の課題は、ビジネスプロセスの効率化であり、業務の改善を通した活動を支援します。',
    groupList: [
      {
        text: '株式会社CRドットアイ',
        url: 'https://roopa.jp/',
      },
    ],
    columnNumber: 2,
  },
  {
    serviceName: '採用見える化クラウド',
    imagePathPc: '/service/img/systemsolution/systemsolution2.png',
    imagePathSp: '/service/img/systemsolution/systemsolution2sp.png',
    title: '派遣・アルバイトの採用分析ツール',
    content:
      '主要な採用システムと連携しレポート作成を自動化。応募獲得～入社まではもちろん、入社以降も使用した求人費用がどのように売上・業務に貢献したのかを見える化できます。',
    groupList: [
      {
        text: '株式会社キャスティングロード',
        url: 'https://www.cr2.co.jp/lp/hr-visual/',
      },
    ],
    columnNumber: 2,
  },
  {
    serviceName: 'サブスク型システム開発',
    imagePathPc: '/service/img/systemsolution/systemsolution3.png',
    imagePathSp: '/service/img/systemsolution/systemsolution3sp.png',
    title: 'ITスキル人材を必要な時間だけ活用',
    content:
      '「少しだけエンジニアに技術支援して欲しい」を実現したサブスク型開発・保守サービス。クラウドサービスの導入・開発・運用・保守をサポートいたします。加速するリモートワークや情報共有の需要に対し、即日導入・低コスト・安心安全なクラウドサービスを提供しております。',
    groupList: [
      { text: '株式会社CRドットアイ', url: 'https://crdoti.co.jp/lp/bizplus/' },
    ],
    columnNumber: 2,
  },
  {
    serviceName: 'オンライン通訳・翻訳サービス',
    imagePathPc: '/service/img/systemsolution/systemsolution4.png',
    imagePathSp: '/service/img/systemsolution/systemsolution4sp.png',
    title: '法人向けオンライン通訳・翻訳サービス',
    content:
      '会議からビジネスセミナーまで、1時間単位で用途に合わせた通訳者をコーディネートする「OCiETe通訳」や、独自のデータベースを活用した専門性の高い翻訳サービス「OCiETe翻訳」、その他議事録作成などビジネスシーンでのお困りごとに役立つサービスを提供。',
    groupList: [
      { text: '株式会社オシエテ', url: 'https://ociete.co.jp/#service' },
    ],
    columnNumber: 2,
  },
]

const Page = () => {
  return (
    <LayoutService
      heading="システムソリューション事業"
      headingEnglish={ENGLISH_TITLE.SystemSolution}
      catchCopy="これまで培ってきた採用活動の効率化や業務の改善のノウハウとITを活かし、様々な悩みを抱える企業様の課題を解決します。"
    >
      <div className="bg-white pc:px-8">
        <section className="pc:py-12">
          <ul className="grid gap-x-8 gap-y-12 place-items-center pc:place-items-start pc:grid-cols-2">
            {cardList.map((card, i) => (
              <CardService key={i} {...card} />
            ))}
          </ul>
        </section>
      </div>
    </LayoutService>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEO title="システムソリューション事業" url="/service/systemsolution" />
)
